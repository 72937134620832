@import './toastr-styles/index';

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
